export default {
  state: {
    data: false,
  },
  getters: {
    //
  },
  actions: {
    logout(context) {
      context.commit("updateAuth", false);
    },
    updateAuthField({ commit, state }, { field, value }) {
      const newAuth = { ...state.data, [field]: value };
      commit("updateAuth", newAuth);
    },
  },
  mutations: {
    updateAuth(state, data) {
      return (state.data = data);
    },
  },
};
