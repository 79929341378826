import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import multiguard from "vue-router-multiguard";
Vue.use(VueRouter);

// Logic for login gard for routes
// const loggedIn = function (to, from, next) {
//   if (!store.state.auth.data.token) {
//     next({
//       name: "PageAuthSignin",
//     });
//   } else {
//     next();
//   }
// };

const loggedOut = function (to, from, next) {
  if (store.state.auth.data.token) {
    next({
      name: "PageDashboard",
    });
  } else {
    next();
  }
};

// all route logics
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutApp.vue"),
    // beforeEnter: multiguard([loggedIn]),
    children: [
      // dashboard
      {
        path: "/dashboard",
        name: "PageDashboard",
        component: () =>
          import(
            /* webpackChunkName: "page-PageDashboard" */ "@/views/home/PageDashboard.vue"
          ),
        meta: {
          title: "Dashboard",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: true,
              to: { name: "PageDashboard" },
            },
          ],
        },
      },

      // Categories
      {
        path: "/categories",
        name: "PageCategoryList",
        component: () =>
          import(
            /* webpackChunkName: "page-categories-list" */ "@/views/categories/PageCategoryList.vue"
          ),
        meta: {
          title: "Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Categories",
              disabled: true,
              to: { name: "PageCategoryList" },
            },
          ],
        },
      },
      {
        path: "/categories/new",
        name: "PageNewCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-new-category" */ "@/views/categories/PageNewCategory.vue"
          ),
        meta: {
          title: "Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "New",
              disabled: true,
              to: { name: "PageNewCategory" },
            },
          ],
        },
      },
      {
        path: "/categories/:id",
        name: "PageViewCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-view-category" */ "@/views/categories/PageViewCategory.vue"
          ),
        meta: {
          title: "Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "View",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
      {
        path: "/categories/:id/edit",
        name: "PageEditCategory",
        component: () =>
          import(
            /* webpackChunkName: "page-edit-category" */ "@/views/categories/PageEditCategory.vue"
          ),
        meta: {
          title: "Categories",
          breadcrumbsItems: [
            {
              text: "Dashboard",
              disabled: false,
              to: { name: "PageDashboard" },
            },
            {
              text: "Categories",
              disabled: false,
              to: { name: "PageCategoryList" },
            },
            {
              text: "Edit",
              disabled: true,
              to: "",
            },
          ],
        },
        beforeEnter: (to, from, next) => {
          to.meta.breadcrumbsItems[2].text = to.query.bc;
          next();
        },
      },
    ],
  },
  // Auth Pages
  {
    path: "/auth",
    redirect: "/auth/signin",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    beforeEnter: multiguard([loggedOut]),
    children: [
      {
        path: "signin",
        name: "PageAuthSignin",
        component: () =>
          import(
            /* webpackChunkName: "auth-signin" */ "@/views/auth/Signin.vue"
          ),
        meta: {
          title: "Signin",
        },
      },
      {
        path: "otp",
        name: "PageAuthOTP",
        component: () =>
          import(/* webpackChunkName: "auth-otp" */ "@/views/auth/OTP.vue"),
        meta: {
          title: "OTP",
        },
      },
    ],
  },

  // Error Pages
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "layout-main" */ "@/layouts/LayoutMain.vue"),
    children: [
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () =>
          import(
            /* webpackChunkName: "main-expaired" */ "@/views/error/PageErrorSessionExpired.vue"
          ),
      },
      {
        path: "error/something-went-wrong",
        name: "PageErrorWentWrong",
        component: () =>
          import(
            /* webpackChunkName: "main-something-went-wrong" */ "@/views/error/PageErrorWentWrong.vue"
          ),
      },
      {
        path: "error/not-authorized",
        name: "PageErrorNotAuthorized",
        component: () =>
          import(
            /* webpackChunkName: "main-not-authorized" */ "@/views/error/PageErrorNotAuthorized.vue"
          ),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () =>
          import(
            /* webpackChunkName: "main-not-found" */ "@/views/error/PageErrorNotFound.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? "dealpartner.lk | " + to.meta.title : "dealpartner.lk";
  });
});

export default router;
