import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/mixins/route";
import "@/mixins/string";
import "@/mixins/miscellaneous";
import _ from "lodash";
import moment from "moment";
import "./styles/main.scss";

import { version } from "./../package.json";
Vue.prototype.$version = version;

Vue.prototype.$auth = store.state.auth.data;

Vue.prototype.$_ = _;

Vue.prototype.$moment = moment;

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
